import React, { useState, useEffect, CSSProperties } from "react";
import logo from './logo.svg';
import './App.css';
import GridLoader from "react-spinners/GridLoader";

import Layout from "./pages/Layout";

const override = {
  display: "block",
  width: "100% !important",
  height: "100vh",
  left: "50%",
  top: "50%",
  position: "absolute",
  borderColor: "transparent",
  transform: "translate(-50%, -50%)"
};


function App() {
  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#5dba47");
  const [quote, setQuote] = useState({});
  const getRandomQuote = () => {
    setLoading(true);
    fetch('https://api.quotable.io/random')
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        setQuote(data);
      });
  };

  return (
    <div>
      {loading ? (
        <GridLoader
          color={color}
          loading={loading}
          cssOverride={override}
          size={20}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        <div className="main-content">
          <Layout/>
        </div>
      )}
    </div>
  );
}

export default App;
