import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import $ from 'jquery';
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { isMobile, isTablet } from 'react-device-detect';
import Lottie from "lottie-react";

import homecar from "./lottie/home_car.json";
import homepump from "./lottie/home_pump.json";
import gridngen from "./lottie/grid_ngen.json";
import solarngen from "./lottie/solar_ngen.json";
import homengen from "./lottie/home_ngen.json";
import storagengen from "./lottie/storage_ngen.json";
import ngengrid from "./lottie/ngen_grid.json";
import ngenstorage from "./lottie/ngen_storage.json";
import ngenhome from "./lottie/ngen_home.json";

import Mhomepump from "./lottie/mobile_home_pump.json";
import Mgridngen from "./lottie/mobile_grid_ngen.json";
import Msolarngen from "./lottie/mobile_solar_ngen.json";
import Mhomengen from "./lottie/mobile_home_ngen.json";
import Mstoragengen from "./lottie/mobile_storage_ngen.json";
import Mngengrid from "./lottie/mobile_ngen_grid.json";
import Mngenstorage from "./lottie/mobile_ngen_storage.json";
import Mngenhome from "./lottie/mobile_ngen_home.json";

import GridLoader from "react-spinners/GridLoader";

import './Home.css';

const override = {
    display: "block",
    width: "100% !important",
    height: "auto",
    left: "50%",
    top: "50%",
    position: "absolute",
    borderColor: "transparent",
    transform: "translate(-50%, -50%)"
};

const Home = () => {
    const { t, i18n } = useTranslation();
    let [loading, setLoading] = useState(true);
    let [color, setColor] = useState("#5dba47");
    var timeout = null;


    function connect() {
        timeout = null;
        var client = new W3CWebSocket('wss://eskupnost.ngen.si/ws/');
        client.onopen = (c) => {
            client.send(
                JSON.stringify({
                    type: "action",
                    name: "cleanSubscribeTo",
                    value: ["ENERGY_SUPPLY"],
                })
            );
            setLoading(false);
            $("#liveSign").addClass('isLive');
        };

        if (isMobile && !isTablet) {

        } else {
            var $solarngen = $("#solarngen");
        }

        client.onmessage = (e) => {
            var msgContent = JSON.parse(e.data);
            if (msgContent.channel === "ENERGY_SUPPLY") {
                var dateFormat = new Date(msgContent.value.timestamp);
                var dateFormat = dateFormat.toLocaleDateString('sl-SI') + "   " + ("0" + dateFormat.getHours()).slice(-2) + ":" + ("0" + dateFormat.getMinutes()).slice(-2) + ":" + ("0" + dateFormat.getSeconds()).slice(-2);

                $("#home-value").text(Math.abs(parseInt(msgContent.value.site_p)).toFixed(2));
                $("#solar-value").text(Math.abs(parseInt(msgContent.value.solar_p)).toFixed(2));
                $("#grid-value").text(Math.abs(parseInt(msgContent.value.grid_p)).toFixed(2));
                $("#storage-value").text(Math.abs(parseInt(msgContent.value.p)).toFixed(2));
                var nccStatus = Math.sign(msgContent.value.ncc_status);
                //set variables 
                var homeval = parseInt(msgContent.value.site_p).toFixed(2);
                var solarval = parseInt(msgContent.value.solar_p).toFixed(2);
                var gridval = parseInt(msgContent.value.grid_p).toFixed(2);
                var storageval = parseInt(msgContent.value.p).toFixed(2);

                if (isMobile && !isTablet) {
                    $("#Mhomepump").removeClass('hidden');
                    switch (nccStatus) {
                        case -1:
                            $("#Mgridngen,#Mhomepump,#Mngenstorage,#Mngenhome").addClass('action');
                            $("#akcija").removeClass('hidden');
                            $("#ncc_value").addClass('active').text(t('data.ACTIVE'));
                            break;
                        case 0:
                            $("#Mgridngen,#Mhomepump,#Mngenstorage,#Mngenhome").removeClass('action');
                            $("#Mstoragengen,#Mngenhome").removeClass('selfconsumption');
                            $("#akcija,#selfconsumption").addClass('hidden');
                            $("#ncc_value").removeClass('active').text(t('data.INACTIVE'));
                            break;
                        case 1:
                            $("#Mstoragengen,#Mngenhome").addClass('selfconsumption');
                            $("#selfconsumption").removeClass('hidden');
                            $("#ncc_value").addClass('active').text(t('data.ACTIVE'));
                            break;
                        default:
                            break;
                    }

                    //value grid
                    switch (true) {
                        case (gridval > 0):
                            $("#Mgridngen").addClass('hidden');
                            $("#Mngengrid").removeClass('hidden');
                            $("#gridStatus").text(t('data.production'));
                            break;
                        case (gridval < 0):
                            $("#Mgridngen").removeClass('hidden');
                            $("#Mngengrid").addClass('hidden');
                            $("#gridStatus").text(t('data.consumption'));
                            break;
                        default:
                            break;
                    }

                    //value solar
                    switch (true) {
                        case (solarval < 0):
                            $("#Msolarngen").addClass('hidden');
                            break;
                        case (solarval > 0):
                            $("#Msolarngen").removeClass('hidden');
                            $("#solarStatus").text(t('data.production'));
                            break;
                        default:
                            break;
                    }

                    //value storage
                    switch (true) {
                        case (storageval < 0):
                            $("#Mstoragengen").addClass('hidden');
                            $("#Mngenstorage").removeClass('hidden');
                            $("#storageStatus").text(t('data.charging'));
                            break;
                        case (storageval > 0):
                            $("#Mstoragengen").removeClass('hidden');
                            $("#Mngenstorage").addClass('hidden');
                            $("#storageStatus").text(t('data.discharging'));
                            break;
                        default:
                            break;
                    }

                    //home value
                    switch (true) {
                        case (homeval < 0):
                            $("#Mhomengen").addClass('hidden');
                            $("#Mngenhome").removeClass('hidden');
                            break;
                        case (homeval > 0):
                            $("#Mhomengen").removeClass('hidden');
                            $("#Mngenhome").addClass('hidden');
                            break;
                        default:
                            break;
                    }
                } else {
                    $("#homecar").removeClass('hidden');
                    $("#homepump").removeClass('hidden');
                    switch (nccStatus) {
                        case -1:
                            $("#homecar,#homepump,#gridngen,#ngenstorage,#ngenhome").addClass('action');
                            $("#akcija").removeClass('hidden');
                            $("#ncc_value").addClass('active').text(t('data.ACTIVE'));
                            break;
                        case 0:
                            $("#homecar,#homepump,#gridngen,#ngenstorage,#ngenhome").removeClass('action');
                            $("#storagengen,#ngenhome").removeClass('selfconsumption');

                            $("#akcija,#selfconsumption").addClass('hidden');
                            $("#ncc_value").removeClass('active').text(t('data.INACTIVE'));
                            break;
                        case 1:
                            $("#storagengen,#ngenhome").addClass('selfconsumption');
                            $("#selfconsumption").removeClass('hidden');
                            $("#ncc_value").addClass('active').text(t('data.ACTIVE'));
                            break;
                        default:
                            break;
                    }

                    //value grid
                    switch (true) {
                        case (gridval > 0):
                            $("#gridngen").addClass('hidden');
                            $("#ngengrid").removeClass('hidden');
                            $("#gridStatus").text(t('data.production'));
                            break;
                        case (gridval < 0):
                            $("#gridngen").removeClass('hidden');
                            $("#ngengrid").addClass('hidden');
                            $("#gridStatus").text(t('data.consumption'));
                            break;
                        default:
                            break;
                    }

                    //value solar
                    switch (true) {
                        case (solarval < 0):
                            $("#solarngen").addClass('hidden');
                            break;
                        case (solarval > 0):
                            $("#solarngen").removeClass('hidden');
                            $("#solarStatus").text(t('data.production'));
                            break;
                        default:
                            break;
                    }

                    //value storage
                    switch (true) {
                        case (storageval < 0):
                            $("#storagengen").addClass('hidden');
                            $("#ngenstorage").removeClass('hidden');
                            $("#storageStatus").text(t('data.charging'));
                            break;
                        case (storageval > 0):
                            $("#storagengen").removeClass('hidden');
                            $("#ngenstorage").addClass('hidden');
                            $("#storageStatus").text(t('data.discharging'));
                            break;
                        default:
                            break;
                    }

                    //home value
                    switch (true) {
                        case (homeval < 0):
                            $("#homengen").addClass('hidden');
                            $("#ngenhome").removeClass('hidden');
                            break;
                        case (homeval > 0):
                            $("#homengen").removeClass('hidden');
                            $("#ngenhome").addClass('hidden');
                            break;
                        default:
                            break;
                    }

                }
                $("#dataAge").text(dateFormat);
            };
        };

        client.onclose = () => {
            client.close();
            console.info('Connection Closed');
            timeout = setTimeout(function () {
                connect();
            }, 3000);
        };

        client.onerror = () => {
            client.close();
            console.error('Connection Error');
            timeout = setTimeout(function () {
                connect();
            }, 3000);
        };
    }
    connect();

    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
    }

    function closeModal() {
        setIsOpen(false);
    }

    const [xlinkHref, setXlinkHref] = useState(getInitialXlinkHref());
    const [backgroundColor, setBackgroundColor] = useState(getInitialBackgroundColor());
  
    useEffect(() => {
      const intervalId = setInterval(() => {
        const newTime = new Date();
        const hours = newTime.getHours();
        const minutes = newTime.getMinutes();
  
        const isDaytime =
          (hours === 6 && minutes >= 30) ||
          (hours > 6 && hours < 18) ||
          (hours === 18 && minutes <= 0);
  
        console.log('isDaytime:', isDaytime);
        console.log('xlinkHref:', xlinkHref);
        console.log('backgroundColor:', backgroundColor);
  
        setXlinkHref(isDaytime ? '../../assets/media/desktop-white.jpg' : '../../assets/media/desktop.jpg');
        setBackgroundColor(isDaytime ? '#ececec' : '#070709');
      }, 1000);
  
      return () => clearInterval(intervalId);
    }, [xlinkHref, backgroundColor]);

    function getInitialXlinkHref() {
        const currentHour = new Date().getHours();
        const currentMinutes = new Date().getMinutes();
        const isDaytime =
            (currentHour === 6 && currentMinutes >= 30) ||
            (currentHour > 6 && currentHour < 18) ||
            (currentHour === 18 && currentMinutes <= 0);

        return isDaytime ? '../../assets/media/desktop-white.jpg' : '../../assets/media/desktop.jpg';
    }

    function getInitialBackgroundColor() {
        return getInitialXlinkHref() === '../../assets/media/desktop-white.jpg' ? '#ececec' : '#070709';
    }
    if (isMobile && !isTablet) {
        return (
            <>
                {loading ? (
                    <GridLoader
                        color={color}
                        loading={loading}
                        cssOverride={override}
                        size={20}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                ) : (
                    <div className='content'>
                        <svg width="72" height="24" viewBox="0 0 72 24" fill="none" xmlns="http://www.w3.org/2000/svg" className='live'>
                            <g id="live">
                                <text id="liveText" fill="#7E8B8B" xmlSpace="preserve" fontFamily="Roboto" fontSize="14" letterSpacing="0em"><tspan x="26" y="17.1818">{t('data.LIVE')}</tspan></text>
                                <g id="liveSign">
                                    <path id="Vector" d="M15.4754 17.0951C15.2925 16.9122 15.1822 16.6619 15.1822 16.3821C15.1795 12.5428 12.0585 9.42175 8.21909 9.41906C7.66484 9.41906 7.21283 8.96705 7.21283 8.4128C7.21283 7.85855 7.66484 7.40654 8.21909 7.40654C13.1697 7.40654 17.1947 11.4316 17.1947 16.3821C17.1947 16.9364 16.7427 17.3884 16.1884 17.3884C15.9086 17.3884 15.6557 17.2754 15.4754 17.0951Z" fill="#FF0000" />
                                    <path id="Vector_2" d="M11.6253 17.0924C11.4423 16.9095 11.332 16.6593 11.332 16.3794C11.332 15.5508 11.0064 14.7678 10.4199 14.1813C9.83338 13.5948 9.05043 13.2692 8.22175 13.2692C7.6675 13.2692 7.2155 12.8172 7.2155 12.2629C7.2155 11.7087 7.6675 11.2567 8.22175 11.2567C9.58854 11.2567 10.8746 11.7894 11.8432 12.758C12.8118 13.7266 13.3445 15.0127 13.3418 16.3821C13.3418 16.9364 12.8898 17.3884 12.3356 17.3884C12.0611 17.3884 11.8082 17.2754 11.6253 17.0924Z" fill="#FF0000" />
                                    <path id="Vector_3" d="M7.26933 17.3319C7.79387 17.8564 8.64431 17.8564 9.16884 17.3319C9.69338 16.8073 9.69338 15.9569 9.16884 15.4324C8.64431 14.9078 7.79387 14.9078 7.26933 15.4324C6.7448 15.9569 6.7448 16.8074 7.26933 17.3319Z" fill="#FF0000" />
                                    <path id="Vector_4" d="M19.3874 17.0951C19.2045 16.9122 19.0942 16.6619 19.0942 16.3821C19.0915 10.385 14.2163 5.50972 8.22177 5.50972C7.66752 5.50972 7.21551 5.05772 7.21551 4.50347C7.21551 3.94922 7.66752 3.49721 8.22177 3.49721C11.6656 3.49721 14.8996 4.83709 17.3346 7.27202C19.7695 9.70694 21.1094 12.941 21.1094 16.3848C21.1094 16.9391 20.6574 17.3911 20.1031 17.3911C19.8206 17.3884 19.5677 17.2754 19.3874 17.0951Z" fill="#FF0000" />
                                </g>
                            </g>
                        </svg>
                        <Lottie animationData={Mgridngen} loop={true} id="Mgridngen" className='hidden' />
                        <Lottie animationData={Mhomepump} loop={true} id="Mhomepump" className='hidden' />
                        <Lottie animationData={Msolarngen} loop={true} id="Msolarngen" className='hidden' />
                        <Lottie animationData={Mhomengen} loop={true} id="Mhomengen" className='hidden' />
                        <Lottie animationData={Mstoragengen} loop={true} id="Mstoragengen" className='hidden' />
                        <Lottie animationData={Mngengrid} loop={true} id="Mngengrid" className='hidden' />
                        <Lottie animationData={Mngenstorage} loop={true} id="Mngenstorage" className='hidden' />
                        <Lottie animationData={Mngenhome} loop={true} id="Mngenhome" className='hidden' />

                        <div className="title">
                            <h2>{t('data.title1')}</h2>
                            <h1>{t('data.title2')}</h1>
                        </div>

                        <svg id="mobilengen" width="100vw" viewBox="0 0 410 492" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                            <g id="Mngen">
                                <rect id="mobile" width="410" height="492" fill="url(#pattern0)" />
                                <g id="solar">
                                    <path id="Vector" d="M138.5 5.5L138 127" stroke="#7E8B8B" strokeMiterlimit="10" />
                                    <text id="SON&#196;&#140;NA - Poraba" fill="#5DBB46" xmlSpace="preserve" fontFamily="Roboto" fontSize="12" letterSpacing="0em"><tspan x="145" y="12.72">{t('data.SOLAR')} - <tspan id="solarStatus">{t('data.production')}</tspan></tspan></text>
                                    <text id="00.00 kW" fill="white" xmlSpace="preserve" fontFamily="Roboto" fontSize="19" fontWeight="bold" letterSpacing="0em"><tspan id="solar-value" x="145" y="35.4056">00.00</tspan> kW</text>
                                    <path id="Vector_2" d="M133 130.088C133 132.898 135.278 135.176 138.088 135.176C140.897 135.176 143.176 132.898 143.176 130.088C143.176 127.278 140.897 125 138.088 125C135.278 125 133 127.278 133 130.088ZM140.433 130.088C140.433 131.383 139.383 132.433 138.088 132.433C136.793 132.433 135.743 131.383 135.743 130.088C135.743 128.793 136.793 127.743 138.088 127.743C139.383 127.743 140.433 128.793 140.433 130.088Z" fill="#5DBB46" />
                                </g>
                                <g id="storage">
                                    <text id="EL. HRANILNIK" fill="#5DBB46" xmlSpace="preserve" fontFamily="Roboto" fontSize="12" letterSpacing="0em"><tspan x="73.0002" y="402.72">{t('data.STORAGE')} - <tspan id="storageStatus">{t('data.discharging')}</tspan></tspan></text>
                                    <text id="00.00 kW_2" fill="white" xmlSpace="preserve" fontFamily="Roboto" fontSize="19" fontWeight="bold" letterSpacing="0em"><tspan id="storage-value" x="73" y="425.406">00.00</tspan> kW</text>
                                    <path id="Vector_3" d="M68 423L68 354" stroke="#7E8B8B" strokeMiterlimit="10" />
                                    <path id="Vector_4" d="M63 350.088C63 352.897 65.2782 355.176 68.0879 355.176C70.8975 355.176 73.1757 352.897 73.1757 350.088C73.1757 347.278 70.8975 345 68.0879 345C65.2782 345 63 347.278 63 350.088ZM70.4325 350.088C70.4325 351.383 69.3831 352.433 68.0879 352.433C66.7926 352.433 65.7432 351.383 65.7432 350.088C65.7432 348.793 66.7926 347.743 68.0879 347.743C69.3831 347.743 70.4325 348.793 70.4325 350.088Z" fill="#5DBB46" />
                                </g>
                                <g id="grid">
                                    <text id="NGEN FLEX Status" fill="#5DBB46" xmlSpace="preserve" fontFamily="Roboto" fontSize="12" letterSpacing="0em"><tspan x="268" y="445.72">NGEN FLEX Status</tspan></text>
                                    <text id="NEAKTIVNO" fill="white" xmlSpace="preserve" fontFamily="Roboto" fontSize="19" fontWeight="bold" letterSpacing="0em"><tspan id="ncc_value" x="268" y="468.08">{t('data.INACTIVE')}</tspan></text>
                                    <path id="Vector_5" d="M261 422L261 354" stroke="#7E8B8B" strokeMiterlimit="10" />
                                    <path id="Vector_6" d="M256 350.088C256 352.897 258.278 355.176 261.088 355.176C263.897 355.176 266.176 352.897 266.176 350.088C266.176 347.278 263.897 345 261.088 345C258.278 345 256 347.278 256 350.088ZM263.433 350.088C263.433 351.383 262.383 352.433 261.088 352.433C259.793 352.433 258.743 351.383 258.743 350.088C258.743 348.793 259.793 347.743 261.088 347.743C262.383 347.743 263.433 348.793 263.433 350.088Z" fill="#5DBB46" />
                                    <text id="OMRE&#197;&#189;JE - Poraba" fill="#5DBB46" xmlSpace="preserve" fontFamily="Roboto" fontSize="12" letterSpacing="0em"><tspan x="268" y="403.72">{t('data.GRID')} - <tspan id="gridStatus">{t('data.consumption')}</tspan></tspan></text>
                                    <text id="00.00 kW_3" fill="white" xmlSpace="preserve" fontFamily="Roboto" fontSize="19" fontWeight="bold" letterSpacing="0em"><tspan id="grid-value" x="268" y="426.08">00.00</tspan> kW</text>
                                </g>
                                <g id="home">
                                    <text id="HI&#197;&#160;E - Poraba" fill="#5DBB46" xmlSpace="preserve" fontFamily="Roboto" fontSize="12" letterSpacing="0em"><tspan x="290" y="12.72">{t('data.HOME')} - <tspan id="homeStatus">{t('data.consumption')}</tspan></tspan></text>
                                    <text id="00.00 kW_4" fill="white" xmlSpace="preserve" fontFamily="Roboto" fontSize="19" fontWeight="bold" letterSpacing="0em"><tspan id="home-value" x="290" y="35.4057">00.00</tspan> kW</text>
                                    <path id="Vector_7" d="M283 5.5V220" stroke="#7E8B8B" strokeMiterlimit="10" />
                                    <path id="Vector_8" d="M278 223.088C278 225.897 280.278 228.176 283.088 228.176C285.897 228.176 288.176 225.897 288.176 223.088C288.176 220.278 285.897 218 283.088 218C280.278 218 278 220.278 278 223.088ZM285.433 223.088C285.433 224.383 284.383 225.433 283.088 225.433C281.793 225.433 280.743 224.383 280.743 223.088C280.743 221.793 281.793 220.743 283.088 220.743C284.383 220.743 285.433 221.793 285.433 223.088Z" fill="#5DBB46" />
                                </g>
                                <g id="akcija" className="hidden">
                                    <text id="akcijska-cena" fill="#5DBB46" xmlSpace="preserve" fontFamily="Roboto" fontSize="12" fontWeight="bold" letterSpacing="0em"><tspan x="268" y="340.72">{t('data.action')} -%</tspan></text>
                                </g>
                                <g id="selfconsumption" className="hidden">
                                    <text id="samo-poraba" fill="#5DBB46" xmlSpace="preserve" fontFamily="Roboto" fontSize="12" fontWeight="bold" letterSpacing="0em"><tspan x="268" y="340.72">{t('data.selfconsumption')}</tspan></text>
                                </g>
                            </g>
                            <defs>
                                <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                                    <use xlinkHref="#houseimage1" transform="scale(0.000924499 0.000770416)" />
                                </pattern>
                                <filter id="filter0_d_59_3" x="248.03" y="313.65" width="132.639" height="47.46" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feOffset />
                                    <feGaussianBlur stdDeviation="10" />
                                    <feComposite in2="hardAlpha" operator="out" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0" />
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_59_3" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_59_3" result="shape" />
                                </filter>
                                <image id="houseimage1" data-name="mobile.png" width="1082" height="1298" xlinkHref="../../assets/media/mobile.png" />
                            </defs>
                        </svg>

                        <div className="footer">
                            <div className='left'>
                                <div><span className="time">{t('data.Measurement-time')}: </span><span id="dataAge"></span></div>
                            </div>
                            <div className='right'>
                                <div className="explanation" onClick={openModal}>{t('data.Explanation')}</div>
                            </div>
                        </div>
                        <div id="modal" className={modalIsOpen ? 'modal open' : 'modal close'} onClick={closeModal}>
                            <div className="modal-content">
                                <span className="close" onClick={closeModal}>&times;</span>
                                <div className="modal-text">
                                    <h2>{t('data.Explanation')}</h2>
                                    <p>{t('data.expl1')}</p>
                                    <h4>{t('data.expl2')}</h4>
                                    <p>{t('data.expl3')}</p>
                                    <h4>{t('data.expl4')}</h4>
                                    <p>{t('data.expl5')}</p>
                                    <h4>{t('data.expl6')}</h4>
                                    <p>{t('data.expl7')}</p>
                                    <h4>{t('data.expl8')}</h4>
                                    <p>{t('data.expl9')}</p>
                                    <h4>{t('data.expl10')}</h4>
                                    <p>{t('data.expl11')}</p>
                                    <ul>
                                        <li>
                                            <h4>{t('data.expl12')}</h4>
                                            <p>{t('data.expl13')}</p>
                                        </li>
                                        <li>
                                            <h4>{t('data.expl14')}</h4>
                                            <p>{t('data.expl15')}</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </>
        )
    } else {
        return (
            <>
            <div className='content'>
                {/* <div className='content' style={{ backgroundColor }}> */}
                    <svg width="72" height="24" viewBox="0 0 72 24" fill="none" xmlns="http://www.w3.org/2000/svg" className='live'>
                        <g id="live">
                            <text id="liveText" fill="#7E8B8B" xmlSpace="preserve" fontFamily="Roboto" fontSize="14" letterSpacing="0em"><tspan x="26" y="17.1818">{t('data.LIVE')}</tspan></text>
                            <g id="liveSign">
                                <path id="Vector" d="M15.4754 17.0951C15.2925 16.9122 15.1822 16.6619 15.1822 16.3821C15.1795 12.5428 12.0585 9.42175 8.21909 9.41906C7.66484 9.41906 7.21283 8.96705 7.21283 8.4128C7.21283 7.85855 7.66484 7.40654 8.21909 7.40654C13.1697 7.40654 17.1947 11.4316 17.1947 16.3821C17.1947 16.9364 16.7427 17.3884 16.1884 17.3884C15.9086 17.3884 15.6557 17.2754 15.4754 17.0951Z" fill="#FF0000" />
                                <path id="Vector_2" d="M11.6253 17.0924C11.4423 16.9095 11.332 16.6593 11.332 16.3794C11.332 15.5508 11.0064 14.7678 10.4199 14.1813C9.83338 13.5948 9.05043 13.2692 8.22175 13.2692C7.6675 13.2692 7.2155 12.8172 7.2155 12.2629C7.2155 11.7087 7.6675 11.2567 8.22175 11.2567C9.58854 11.2567 10.8746 11.7894 11.8432 12.758C12.8118 13.7266 13.3445 15.0127 13.3418 16.3821C13.3418 16.9364 12.8898 17.3884 12.3356 17.3884C12.0611 17.3884 11.8082 17.2754 11.6253 17.0924Z" fill="#FF0000" />
                                <path id="Vector_3" d="M7.26933 17.3319C7.79387 17.8564 8.64431 17.8564 9.16884 17.3319C9.69338 16.8073 9.69338 15.9569 9.16884 15.4324C8.64431 14.9078 7.79387 14.9078 7.26933 15.4324C6.7448 15.9569 6.7448 16.8074 7.26933 17.3319Z" fill="#FF0000" />
                                <path id="Vector_4" d="M19.3874 17.0951C19.2045 16.9122 19.0942 16.6619 19.0942 16.3821C19.0915 10.385 14.2163 5.50972 8.22177 5.50972C7.66752 5.50972 7.21551 5.05772 7.21551 4.50347C7.21551 3.94922 7.66752 3.49721 8.22177 3.49721C11.6656 3.49721 14.8996 4.83709 17.3346 7.27202C19.7695 9.70694 21.1094 12.941 21.1094 16.3848C21.1094 16.9391 20.6574 17.3911 20.1031 17.3911C19.8206 17.3884 19.5677 17.2754 19.3874 17.0951Z" fill="#FF0000" />
                            </g>
                        </g>
                    </svg>
                    {loading ? (
                        <GridLoader
                            color={color}
                            loading={loading}
                            cssOverride={override}
                            size={20}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    ) : (
                        <>
                            <Lottie animationData={gridngen} loop={true} id="gridngen" className='hidden' />
                            <Lottie animationData={solarngen} loop={true} id="solarngen" className='hidden' />
                            <Lottie animationData={homengen} loop={true} id="homengen" className='hidden' />
                            <Lottie animationData={storagengen} loop={true} id="storagengen" className='hidden' />
                            <Lottie animationData={ngengrid} loop={true} id="ngengrid" className='hidden' />
                            <Lottie animationData={ngenstorage} loop={true} id="ngenstorage" className='hidden' />
                            <Lottie animationData={ngenhome} loop={true} id="ngenhome" className='hidden' />
                            <Lottie animationData={homecar} loop={true} id="homecar" className='hidden' />
                            <Lottie animationData={homepump} loop={true} id="homepump" className='hidden' />
                        </>
                    )}
                    <div className="title">
                        <h2>{t('data.title1')}</h2>
                        <h1>{t('data.title2')}</h1>
                    </div>


                    <svg id="desktopngen" width="100vw" viewBox="0 0 3840 2160" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                        <g id="ngen">
                            <rect id="Rectangle" width="3840" height="2160" fill="url(#pattern0)" />
                            <g id="grid">
                                <path id="Vector" opacity="0.9" d="M704.85 1391.19H186.3V1751.96H704.85V1391.19Z" fill="#1A1A1A" stroke="#7E8B8B" strokeWidth="2" strokeMiterlimit="10" />
                                <text id="NGEN FLEX Status" fill="#5DBB46" xmlSpace="preserve" fontFamily="Roboto" fontSize="37.8176" letterSpacing="0em"><tspan x="207.392" y="1624.46">NGEN FLEX Status</tspan></text>
                                <text id="NEAKTIVNO" fill="white" xmlSpace="preserve" fontFamily="Roboto" fontSize="74.2168" fontWeight="bold" letterSpacing="0em"><tspan id="ncc_value" x="207.332" y="1701.69">{t('data.INACTIVE')}</tspan></text>
                                <path id="Vector_2" d="M704.85 1751.96H1031.5" stroke="#7E8B8B" strokeMiterlimit="10" />
                                <path id="Vector_3" d="M1041.29 1736.64C1032.83 1736.64 1025.97 1743.5 1025.97 1751.96C1025.97 1760.42 1032.83 1767.28 1041.29 1767.28C1049.75 1767.28 1056.61 1760.42 1056.61 1751.96C1056.61 1743.5 1049.75 1736.64 1041.29 1736.64ZM1041.29 1759.02C1037.39 1759.02 1034.23 1755.86 1034.23 1751.96C1034.23 1748.06 1037.39 1744.9 1041.29 1744.9C1045.19 1744.9 1048.35 1748.06 1048.35 1751.96C1048.35 1755.86 1045.19 1759.02 1041.29 1759.02Z" fill="#5DBB46" />
                                <text id="OMREZJE" fill="#5DBB46" xmlSpace="preserve" fontFamily="Roboto" fontSize="37.8176" letterSpacing="0em"><tspan x="207.341" y="1460.81">{t('data.GRID')} - <tspan id="gridStatus">{t('data.consumption')}</tspan></tspan></text>
                                <text fill="white" xmlSpace="preserve" fontFamily="Roboto" fontSize="74.2168" fontWeight="bold" letterSpacing="0em"><tspan id="grid-value" x="207.341" y="1538.04">00.00</tspan> kW</text>
                            </g>
                            <g id="solar">
                                <path id="Vector_10" opacity="0.9" d="M2650.6 440.7H2132.05V642.55H2650.6V440.7Z" fill="#1A1A1A" stroke="#7E8B8B" strokeWidth="2" strokeMiterlimit="10" />
                                <path id="Vector_11" d="M2132.05 642.29V783.5" stroke="#7E8B8B" strokeWidth="2" strokeMiterlimit="10" />
                                <path id="Vector_12" d="M2147.36 794.32C2147.36 785.86 2140.5 779 2132.04 779C2123.58 779 2116.72 785.86 2116.72 794.32C2116.72 802.78 2123.58 809.64 2132.04 809.64C2140.51 809.64 2147.36 802.78 2147.36 794.32ZM2124.98 794.32C2124.98 790.42 2128.14 787.26 2132.04 787.26C2135.94 787.26 2139.1 790.42 2139.1 794.32C2139.1 798.22 2135.94 801.38 2132.04 801.38C2128.14 801.38 2124.98 798.22 2124.98 794.32Z" fill="#5DBB46" />
                                <text id="SONCNA" fill="#5DBB46" xmlSpace="preserve" fontFamily="Roboto" fontSize="37.8176" letterSpacing="0em"><tspan x="2153.09" y="510.326">{t('data.SOLAR')} - <tspan id="solarStatus">{t('data.production')}</tspan></tspan></text>
                                <text fill="white" xmlSpace="preserve" fontFamily="Roboto" fontSize="74.2168" fontWeight="bold" letterSpacing="0em"><tspan id="solar-value" x="2153.09" y="587.552">00.00</tspan> kW</text>
                            </g>
                            <g id="storage">
                                <path id="Vector_13" opacity="0.9" d="M2640.89 1614.93H2122.34V1816.78H2640.89V1614.93Z" fill="#1A1A1A" stroke="#7E8B8B" strokeWidth="2" strokeMiterlimit="10" />
                                <path id="Vector_14" d="M2122.34 1614.93V1436.36" stroke="#7E8B8B" strokeWidth="2" strokeMiterlimit="10" />
                                <path id="Vector_15" d="M2107.02 1426.9C2107.02 1435.36 2113.88 1442.22 2122.34 1442.22C2130.8 1442.22 2137.66 1435.36 2137.66 1426.9C2137.66 1418.44 2130.8 1411.58 2122.34 1411.58C2113.88 1411.58 2107.02 1418.44 2107.02 1426.9ZM2129.41 1426.9C2129.41 1430.8 2126.25 1433.96 2122.35 1433.96C2118.45 1433.96 2115.29 1430.8 2115.29 1426.9C2115.29 1423 2118.45 1419.84 2122.35 1419.84C2126.24 1419.83 2129.41 1422.99 2129.41 1426.9Z" fill="#5DBB46" />
                                <text id="EL. HRANILNIK" fill="#5DBB46" xmlSpace="preserve" fontFamily="Roboto" fontSize="37.8176" letterSpacing="0em"><tspan x="2143.39" y="1684.55">{t('data.STORAGE')} - <tspan id="storageStatus">{t('data.discharging')}</tspan></tspan></text>
                                <text fill="white" xmlSpace="preserve" fontFamily="Roboto" fontSize="74.2168" fontWeight="bold" letterSpacing="0em"><tspan id="storage-value" x="2143.39" y="1761.78">00.00</tspan> kW</text>
                            </g>
                            <g id="akcija" className="hidden">
                                <text id="akcijska-cena" fill="#5DBB46" xmlSpace="preserve" fontFamily="Roboto" fontSize="50" fontWeight="bold" letterSpacing="0em"><tspan x="910" y="1867.97">{t('data.action')} -%</tspan></text>
                            </g>
                            <g id="selfconsumption" className="hidden">
                                <text id="samo-poraba" fill="#5DBB46" xmlSpace="preserve" fontFamily="Roboto" fontSize="50" fontWeight="bold" letterSpacing="0em"><tspan x="910" y="1867.97">{t('data.selfconsumption')}</tspan></text>
                            </g>
                            <g id="home">
                                <path id="Vector_16" opacity="0.9" d="M3255.34 728.72H2736.79V930.57H3255.34V728.72Z" fill="#1A1A1A" stroke="#7E8B8B" strokeWidth="2" strokeMiterlimit="10" />
                                <path id="Vector_17" d="M2736.79 1144.72V931" stroke="#7E8B8B" strokeWidth="2" strokeMiterlimit="10" />
                                <path id="Vector_18" d="M2721.47 1153.34C2721.47 1161.8 2728.33 1168.66 2736.79 1168.66C2745.25 1168.66 2752.11 1161.8 2752.11 1153.34C2752.11 1144.88 2745.25 1138.02 2736.79 1138.02C2728.33 1138.02 2721.47 1144.88 2721.47 1153.34ZM2743.85 1153.34C2743.85 1157.24 2740.69 1160.4 2736.79 1160.4C2732.89 1160.4 2729.73 1157.24 2729.73 1153.34C2729.73 1149.44 2732.89 1146.28 2736.79 1146.28C2740.69 1146.28 2743.85 1149.44 2743.85 1153.34Z" fill="#5DBB46" />
                                <text id="HI&#197;&#160;E - Poraba" fill="#5DBB46" xmlSpace="preserve" fontFamily="Roboto" fontSize="37.8176" letterSpacing="0em"><tspan x="2757.83" y="798.336">{t('data.HOME')} - <tspan id="homeStatus">{t('data.consumption')}</tspan></tspan></text>
                                <text fill="white" xmlSpace="preserve" fontFamily="Roboto" fontSize="74.2168" fontWeight="bold" letterSpacing="0em"><tspan id="home-value" x="2757.83" y="875.563">00.00</tspan> kW</text>
                            </g>

                        </g>
                        <svg key={xlinkHref}>
                            <defs>
                                <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                                    <use xlinkHref="#houseimage2" transform="scale(0.000260417 0.000462963)" />
                                </pattern>
                                <image id="houseimage2" width="3840" height="2160" xlinkHref='../../assets/media/desktop.jpg' />
                                {/* <image id="houseimage2" width="3840" height="2160" xlinkHref={xlinkHref} /> */}
                            </defs>
                        </svg>
                    </svg>

                    <div className="footer">
                        <div className='left'>
                            <div><span className="time">{t('data.Measurement-time')}: </span><span id="dataAge"></span><span className='gdot' />
                                <button className="explanation" onClick={openModal}>{t('data.Explanation')}</button></div>
                        </div>
                        <div className='right'>
                            <div className="copyright">© ngen.si - {t('data.copyright')}</div>
                        </div>
                    </div>
                    <div id="modal" className={modalIsOpen ? 'modal open' : 'modal close'} onClick={closeModal}>
                        <div className="modal-content">
                            <span className="close" onClick={closeModal}>&times;</span>
                            <div className="modal-text">
                                <h2>{t('data.Explanation')}</h2>
                                <p>{t('data.expl1')}</p>
                                <h4>{t('data.expl2')}</h4>
                                <p>{t('data.expl3')}</p>
                                <h4>{t('data.expl4')}</h4>
                                <p>{t('data.expl5')}</p>
                                <h4>{t('data.expl6')}</h4>
                                <p>{t('data.expl7')}</p>
                                <h4>{t('data.expl8')}</h4>
                                <p>{t('data.expl9')}</p>
                                <h4>{t('data.expl10')}</h4>
                                <p>{t('data.expl11')}</p>
                                <ul>
                                    <li>
                                        <h4>{t('data.expl12')}</h4>
                                        <p>{t('data.expl13')}</p>
                                    </li>
                                    <li>
                                        <h4>{t('data.expl14')}</h4>
                                        <p>{t('data.expl15')}</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}

export default Home