import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';

const path = process.env.PUBLIC_URL + '/locales/{{lng}}/{{ns}}.json';

const languageDetector = {
    type: 'languageDetector',
    async: true,
    detect: (callback) => {
        const language = window.location.pathname.split('/')[1];
        callback(language);
    },
    init: () => {},
    cacheUserLanguage: () => {}
};

i18n
  .use(Backend)
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    ns: ['translation'],
    defaultNS: 'translation',
    supportedLngs: [
      'en', 'sl'
    ],
    interpolation: {
      escapeValue: false,
    },
    whitelist: [
      'en', 'sl'
    ],
    fallbackLng: 'en',
    detection: languageDetector,
    backend: {
      loadPath: path
  }
  });


  i18n.on('languageChanged', (lng) => { 
    localStorage.setItem('language', lng);
    document.documentElement.setAttribute('lang', lng); 
  })