import React, { useState }  from 'react';
import './Layout.css';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Home from './Home';
import { BrowserView, MobileView } from 'react-device-detect';

function Layout() {
    const { lang } = useParams()
    const lngs = [{ code: 'en', nativeName: 'English' }, { code: 'sl', nativeName: 'Slovenščina' },]
    const { t, i18n } = useTranslation()
    const [currentLanguage, setLanguage] = useState('en')
    const changeLanguage = value => {
        i18n.changeLanguage(value).then(() => setLanguage(value) && console.log(value)).catch(err => console.log(err))
    }
    return (
        <div className='background'>
            <div className="logo">
                <img src="/assets/media/logo.svg" alt="Ngen logo"/>
            </div>
            <div className='languageSwitcher'>
                <button className={`langselector ${document.documentElement.lang === 'sl' ? 'active' : ''}`} key='sl' onClick={() => changeLanguage('sl')}>SLO</button>/
                <button className={`langselector ${document.documentElement.lang === 'en' ? 'active' : ''}`} key='en' onClick={() => changeLanguage('en')}>ENG</button>
            </div>
<div className="homecontent">
            <Home />
            </div>
            <div id="error">
                <img src="../../assets/media/rotate.jpg" alt="rotate phone" />
            </div>
        </div>
    )
}
export default Layout